import React from "react";

const Testimonials = () => {
    const testimonials = [
        {
            quote: "Spencer was excellent to work with, delivering our project on time and with professionalism. He guided us from the start, clearly explaining our options, timelines, and costs, ensuring we made an informed investment in the right solution for our needs.",
            author: "Matt Kauer",
            title: "COO, FlowState",
            image: "/matt_headshot.png"
        },
        {
            quote: "Spencer built our website and optimized it for SEO with speed, efficiency, and great attention to detail. His passion and clear communication made the process seamless and the results exceptional.",
            author: "Nicholas Boris",
            title: "Owner, Arkansas Muscle & Joint",
            image: "nick_headshot.png"
        }
        // },
        // {
        //     quote: "Outstanding database optimization work. Our queries are now 10x faster, and the improved architecture has dramatically reduced our operational costs.",
        //     author: "Jessica Martinez",
        //     title: "Head of Engineering, ScaleUp Solutions",
        //     image: "/api/placeholder/100/100"
        // },
        // {
        //     quote: "Their cloud migration strategy was flawless. We experienced zero downtime during transition and now have a much more reliable infrastructure.",
        //     author: "David Kim",
        //     title: "VP of Technology, CloudFirst Inc",
        //     image: "/api/placeholder/100/100"
        // }
    ];

    return (
        <section id='testimonials' className="relative bg-blue-950 overflow-hidden py-24">

            <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="text-center">
                    <h2 className="text-3xl sm:text-4xl lg:text-5xl font-bold tracking-tight text-white">
                        Dont just take our word for it
                    </h2>
                    <p className="mt-6 text-lg text-gray-300 max-w-3xl mx-auto">
                        See what our clients have to say about their experience working with us.
                    </p>
                </div>

                <div className="mt-20 grid grid-cols-1 gap-8 md:grid-cols-2">
                    {testimonials.map((testimonial, index) => (
                        <div
                            key={index}
                            className="h-full"
                        >
                            <div className="relative h-full bg-gradient-to-br from-blue-900/50 to-blue-800/30 rounded-3xl p-8 backdrop-blur-sm border border-blue-800/50 hover:border-blue-700/50 transition-all duration-300 group">
                                {/* Gradient Border Effect on Hover */}
                                <div className="absolute inset-0 rounded-3xl bg-gradient-to-r from-blue-500/0 to-cyan-500/0 group-hover:from-blue-500/10 group-hover:to-cyan-500/10 transition-all duration-500" />
                                
                                {/* Quote Icon */}
                                <div className="absolute -top-4 -left-4">
                                    <div className="w-8 h-8 bg-gradient-to-r from-blue-500 to-cyan-500 rounded-full flex items-center justify-center shadow-lg">
                                        <svg 
                                            className="w-4 h-4 text-white" 
                                            fill="currentColor" 
                                            viewBox="0 0 24 24"
                                        >
                                            <path d="M9.75 8.25L6.75 13.5H9.75V18.75H2.25V13.5L5.25 8.25H9.75ZM19.5 8.25L16.5 13.5H19.5V18.75H12V13.5L15 8.25H19.5Z" />
                                        </svg>
                                    </div>
                                </div>

                                {/* Content */}
                                <div className="relative z-10 flex flex-col h-full">
                                    <p className="text-gray-300 text-lg leading-relaxed flex-grow">
                                        "{testimonial.quote}"
                                    </p>
                                    
                                    <div className="mt-8 flex items-center">
                                        <div className="rounded-full p-0.5 bg-gradient-to-r from-blue-500 to-cyan-500">
                                            <img
                                                className="h-12 w-12 rounded-full object-cover border-2 border-blue-950"
                                                src={testimonial.image}
                                                alt={testimonial.author}
                                            />
                                        </div>
                                        <div className="ml-4">
                                            <div className="text-lg font-semibold text-white">
                                                {testimonial.author}
                                            </div>
                                            <div className="text-sm text-blue-400">
                                                {testimonial.title}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Large Quote Mark */}
                                <div className="absolute -bottom-2 -right-2 text-blue-500/5">
                                    <svg 
                                        className="w-32 h-32" 
                                        fill="currentColor" 
                                        viewBox="0 0 24 24"
                                    >
                                        <path d="M9.75 8.25L6.75 13.5H9.75V18.75H2.25V13.5L5.25 8.25H9.75ZM19.5 8.25L16.5 13.5H19.5V18.75H12V13.5L15 8.25H19.5Z" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Testimonials;