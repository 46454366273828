import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';

const TermsOfService = () => {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <div className="bg-gradient-to-b from-blue-950 to-black min-h-screen text-gray-100">
      <div className="max-w-4xl mx-auto px-4 py-8 md:py-16">
        <button 
          onClick={handleGoBack} 
          className="group flex items-center text-gray-400 hover:text-white transition-colors duration-200 mb-12"
        >
          <ArrowLeft className="mr-2 group-hover:-translate-x-1 transition-transform duration-200" size={20} />
          <span className="text-sm font-medium">Back</span>
        </button>

        <h1 className="text-4xl md:text-5xl font-bold leading-tight bg-gradient-to-r from-white to-gray-300 bg-clip-text text-transparent mb-12">
          Terms of Service
        </h1>

        <div className="space-y-6 text-gray-300">
          <p>Last updated: {new Date().toLocaleDateString()}</p>

          <h2 className="text-2xl font-semibold text-white mt-8">1. Acceptance of Terms</h2>
          <p>
            By accessing and using this website, you accept and agree to be bound by the terms and provisions
            of this agreement.
          </p>

          <h2 className="text-2xl font-semibold text-white mt-8">2. Use License</h2>
          <p>
            Permission is granted to temporarily download one copy of the materials (information or software)
            on Dobbs Technologies's website for personal, non-commercial transitory viewing only.
          </p>

          <h2 className="text-2xl font-semibold text-white mt-8">3. Disclaimer</h2>
          <p>
            The materials on Dobbs Technologies's website are provided on an 'as is' basis. We make no
            warranties, expressed or implied, and hereby disclaim and negate all other warranties including,
            without limitation, implied warranties or conditions of merchantability, fitness for a particular
            purpose, or non-infringement of intellectual property or other violation of rights.
          </p>

          <h2 className="text-2xl font-semibold text-white mt-8">4. Limitations</h2>
          <p>
            In no event shall Dobbs Technologies or its suppliers be liable for any damages (including,
            without limitation, damages for loss of data or profit, or due to business interruption) arising
            out of the use or inability to use the materials on our website.
          </p>
        </div>
      </div>
    </div>
  );
};

export default TermsOfService;