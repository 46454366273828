import React from "react";

const handleContactClick = (e) => {
    e.preventDefault();
    const contactSection = document.getElementById('contact');
    if (contactSection) {
        contactSection.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
};

const CTASection = () => {
    return (
        <section className="bg-blue-950 py-24 relative overflow-hidden">
            {/* Decorative Elements */}

            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative z-10">
                <div className="bg-gradient-to-br from-blue-900/50 to-blue-800/30 rounded-3xl p-8 md:p-12 lg:p-16 backdrop-blur-sm border border-blue-800/50">
                    <div className="grid gap-8 md:grid-cols-2 items-center">
                        {/* Left Column - Text Content */}
                        <div className="max-w-xl">
                            <h2 className="text-3xl md:text-4xl font-bold text-white tracking-tight">
                                Let's Build Something Great Together
                            </h2>
                            <p className="mt-4 text-lg text-gray-300 leading-relaxed">
                                Bring your vision to life with our personalized technology solutions. Whether you're scaling up or just starting out, we're here to help you succeed.
                            </p>
                            <div className="mt-8 flex flex-col sm:flex-row gap-4">
                                <a 
                                    href="https://calendly.com/databydobbs-support/30min" 
                                    className="inline-flex items-center justify-center px-6 py-3 rounded-full text-sm font-medium text-blue-950 bg-white hover:bg-gray-100 transition-colors duration-300"
                                >
                                    Book Free Consultation
                                    <svg 
                                        className="ml-2 h-4 w-4" 
                                        fill="none" 
                                        stroke="currentColor" 
                                        viewBox="0 0 24 24"
                                    >
                                        <path 
                                            strokeLinecap="round" 
                                            strokeLinejoin="round" 
                                            strokeWidth={2} 
                                            d="M9 5l7 7-7 7" 
                                        />
                                    </svg>
                                </a>
                                <button
        onClick={handleContactClick}
        className="rounded-full px-8 py-4 text-sm font-semibold text-white border border-white/30 hover:bg-white/10 transition-all duration-300"
    >
        <span className="flex items-center">
            <svg
                className="h-4 w-4 flex-none"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                viewBox="0 0 24 24"
            >
                <path d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
            </svg>
            <span className="ml-3">Contact Us</span>
        </span>
    </button>
                            </div>
                        </div>

                        {/* Right Column - Stats/Benefits */}
                        <div className="grid grid-cols-2 gap-6">
                            <div className="bg-blue-900/30 rounded-2xl p-6 backdrop-blur-sm border border-blue-800/30">
                                <div className="text-3xl font-bold text-white">10+</div>
                                <div className="mt-1 text-sm text-gray-300">Years Analytics Experience</div>
                            </div>
                            <div className="bg-blue-900/30 rounded-2xl p-6 backdrop-blur-sm border border-blue-800/30">
                                <div className="text-3xl font-bold text-white">3</div>
                                <div className="mt-1 text-sm text-gray-300">Enterprise Clients Served</div>
                            </div>
                            <div className="bg-blue-900/30 rounded-2xl p-6 backdrop-blur-sm border border-blue-800/30">
                                <div className="text-3xl font-bold text-white">100%</div>
                                <div className="mt-1 text-sm text-gray-300">Client Retention Rate</div>
                            </div>
                            <div className="bg-blue-900/30 rounded-2xl p-6 backdrop-blur-sm border border-blue-800/30">
                                <div className="text-3xl font-bold text-white">4.9/5</div>
                                <div className="mt-1 text-sm text-gray-300">Client Satisfaction Score</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default CTASection;