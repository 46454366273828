import React from "react";
import { Database, LineChart, Layout, Smartphone,  Brain, Share2 } from "lucide-react";

const OwnerSection = () => {
    const owner = {
        name: "Spencer Dobbs",
        role: "Technical Solutions Specialist",
        image: "/my_profile.jpeg",
        summary: "Helping businesses harness the power of data and technology. I specialize in creating custom solutions that turn complex data into actionable insights, building modern web and mobile applications, and developing intuitive dashboards that drive better business decisions.",
        technologies: [
            { name: "React", type: "Frontend" },
            { name: "Python", type: "Backend" },
            { name: "Swift", type: "Mobile" },
            { name: "SQL", type: "Database" },
            { name: "TypeScript", type: "Frontend" },
            { name: "Node.js", type: "Backend" }
        ],
        services: [
            { 
                name: "Data Solutions",
                description: "Custom databases & data management systems to organize and optimize your business information.",
                Icon: Database,
                color: "text-indigo-400",
                bgColor: "bg-indigo-400/20" 
            },
            { 
                name: "Web Development",
                description: "Modern React applications built with best practices to deliver exceptional user experiences.",
                Icon: Layout,
                color: "text-emerald-400",
                bgColor: "bg-emerald-400/20"
            },
            { 
                name: "Mobile Apps",
                description: "Native iOS applications developed with Swift to reach your customers on their devices.",
                Icon: Smartphone,
                color: "text-cyan-400",
                bgColor: "bg-cyan-400/20"
            },
            { 
                name: "Data Analytics",
                description: "Interactive business intelligence dashboards that transform raw data into actionable insights.",
                Icon: LineChart,
                color: "text-purple-400",
                bgColor: "bg-purple-400/20"
            },
            { 
                name: "Technical Strategy",
                description: "Strategic guidance on technology adoption and integration for business growth.",
                Icon: Brain,
                color: "text-rose-400",
                bgColor: "bg-rose-400/20"
            },
            { 
                name: "Custom Solutions",
                description: "Tailored technological solutions designed specifically for your unique business needs.",
                Icon: Share2,
                color: "text-amber-400",
                bgColor: "bg-amber-400/20"
            }
        ],
        linkedin: "https://www.linkedin.com/in/spencer-dobbs/"
    };

    return (
        <section id='about' className="relative bg-blue-950 overflow-hidden">
            <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-20">
                {/* Header Section */}
                <div className="text-center mb-16">
                    <h2 className="text-4xl font-bold text-white mb-4">Meet the Owner</h2>
                    <p className="text-xl text-gray-300 max-w-3xl mx-auto">
                        With over a decade of experience in technology solutions, I'm passionate about helping businesses succeed in the digital age. My approach combines technical expertise with a deep understanding of business needs to deliver solutions that make a real impact.
                    </p>
                </div>

                <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
                    {/* Left Card */}
                    <div className="bg-gradient-to-br from-blue-900/50 to-blue-800/30 rounded-3xl backdrop-blur-sm border border-blue-800/50 p-8">
                        <div className="flex gap-8 mb-8">
                            <div className="w-28 h-28 rounded-2xl overflow-hidden bg-gradient-to-br from-blue-500 to-cyan-500 p-1">
                                <img
                                    src={owner.image}
                                    alt={owner.name}
                                    className="w-full h-full object-cover rounded-xl"
                                />
                            </div>
                            <div>
                                <h3 className="text-3xl font-bold text-white">{owner.name}</h3>
                                <p className="text-xl text-blue-400 mt-2">{owner.role}</p>
                                <a 
                                    href={owner.linkedin}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="inline-flex items-center gap-2 text-gray-400 hover:text-white transition-colors mt-3"
                                >
                                    <svg className="h-5 w-5" fill="currentColor" viewBox="0 0 24 24">
                                        <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z"/>
                                    </svg>
                                    <span>Connect on LinkedIn</span>
                                </a>
                            </div>
                        </div>

                        <div className="bg-blue-900/30 rounded-xl p-6 border border-blue-800/30 mb-8">
                            <p className="text-gray-300 text-xl leading-relaxed">{owner.summary}</p>
                        </div>

                        <h4 className="text-xl text-white font-semibold mb-4">Technologies</h4>
                        <div className="grid grid-cols-3 gap-3">
                            {owner.technologies.map((tech, i) => (
                                <div 
                                    key={i}
                                    className="bg-blue-900/30 rounded-xl p-4 border border-blue-800/30 text-center"
                                >
                                    <span className="text-lg text-gray-300 block">{tech.name}</span>
                                    <span className="text-sm text-blue-400">{tech.type}</span>
                                </div>
                            ))}
                        </div>
                    </div>

                    {/* Right Card */}
                    <div className="bg-gradient-to-br from-blue-900/50 to-blue-800/30 rounded-3xl backdrop-blur-sm border border-blue-800/50 p-8">
                        <h3 className="text-2xl font-bold text-white mb-8">How I Can Help Your Business</h3>
                        <div className="grid grid-cols-2 gap-5">
                            {owner.services.map((service, i) => (
                                <div 
                                    key={i}
                                    className="bg-blue-900/30 rounded-xl p-6 border border-blue-800/30 flex flex-col min-h-[200px]"
                                >
                                    <div className="flex items-center gap-4 mb-6">
                                        <div className={`rounded-lg p-3 ${service.bgColor}`}>
                                            <service.Icon className={`h-6 w-6 ${service.color}`} />
                                        </div>
                                        <h4 className="text-lg text-white font-medium leading-tight">{service.name}</h4>
                                    </div>
                                    <p className="text-gray-400 text-sm leading-relaxed">{service.description}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default OwnerSection;