import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Header from "./components/header";
import Features from "./components/Features";
import FeatureDetail from "./components/FeatureDetail";
import CTA from "./components/cta";
import AboutUs from "./components/aboutus";
import Testimonials from "./components/testimonials";
import SecondCTA from "./components/secondcta";
import Footer from "./components/Footer";
import CaseStudiesGrid from "./components/pastwork";
import CaseStudyDetail from "./components/CaseStudyDetail";
import PrivacyPolicy from "./components/PrivacyPolicy";
import TermsOfService from "./components/TermsOfService";
import CookiePolicy from "./components/CookiePolicy";
import ContactForm from "./components/ContactForm";

const ScrollHandler = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.search === '?scrollTo=contact') {
      setTimeout(() => {
        const contactSection = document.getElementById('contact');
        if (contactSection) {
          contactSection.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      }, 100);
    }
  }, [location]);

  return null;
};

const HomeLayout = () => (
  <>
    <Features />
    <CTA />
    <AboutUs />
    <CaseStudiesGrid />
    <Testimonials />
    <SecondCTA />
    <ContactForm id="contact" />
  </>
);

const App = () => {
  return (
    <Router>
      <ScrollHandler />
      {/* Header outside Routes so it's always rendered */}
      <Header />
      <Routes>
        <Route path="/" element={<HomeLayout />} />
        <Route path="/case-studies/:slug" element={<CaseStudyDetail />} />
        <Route path="/services/:slug" element={<FeatureDetail />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-of-service" element={<TermsOfService />} />
        <Route path="/cookie-policy" element={<CookiePolicy />} />
      </Routes>
      <Footer />
    </Router>
  );
};

export default App;