import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ArrowLeft, Calendar, Users, Code, Layout, Clock } from 'lucide-react';

const CaseStudyDetail = () => {
  const { slug } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const caseStudyDetails = {
    "chiropractor-website": {
      title: "Website Development for Chiropractic Practice",
      fullDescription: `
        In this comprehensive project, we transformed the online presence of a chiropractic practice through a strategic website development initiative. 
        
        Project Highlights:
        - Developed a robust Django-based marketing website
        - Implemented advanced SEO optimization techniques
        - Created a responsive and user-friendly design
        - Integrated patient booking and contact features
        
        Technical Details:
        - Backend: Django
        - Frontend: Responsive design with modern UI/UX principles
        - SEO Tools: Google Analytics, SEMrush integration
        
        Outcomes:
        - 40% increase in online patient inquiries
        - Improved search engine rankings
        - Enhanced digital brand presence
      `,
      technologiesUsed: [
        "Django",
        "Python",
        "SEO Optimization",
        "Responsive Design",
        "Google Analytics"
      ],
      languages: [
        "Python",
        "JavaScript",
        "HTML",
        "CSS"
      ],
      developmentTypes: [
        "Backend Development",
        "Frontend Development",
        "SEO Optimization",
        "UI/UX Design"
      ],
      image: "/amjchiro.png",
      duration: "3 months",
      teamSize: "6 members"
    },
    "infrastructure-bill-dashboard": {
      title: "Infrastructure Bill Performance Analysis",
      fullDescription: `
        Led a comprehensive analysis of infrastructure bill performance, creating detailed visualizations and reports to support executive decision-making.
        
        Project Highlights:
        - Gathered and analyzed data from multiple third-party sources
        - Created interactive Power BI dashboards for real-time monitoring
        - Developed detailed analysis of inflation impacts
        - Produced executive-level presentations on program progress
        
        Analysis Components:
        - Inflation impact assessment on budget allocation
        - Program progress tracking and visualization
        - Performance metrics and KPI monitoring
        - Trend analysis and forecasting
        
        Outcomes:
        - Comprehensive understanding of inflation effects on the bill
        - Clear visualization of program progress
        - Data-driven insights for stakeholder decisions
      `,
      technologiesUsed: [
        "Power BI",
        "Excel",
        "PowerPoint",
        "Data Analysis Tools",
        "Visualization Software"
      ],
      languages: [
        "DAX",
        "Excel Formulas",
        "SQL Queries"
      ],
      developmentTypes: [
        "Data Analysis",
        "Business Intelligence",
        "Financial Modeling",
        "Report Development"
      ],
      image: "/analytcs_dash.jpeg",
      duration: "4 months",
      teamSize: "5 members"
    },
    "flowstate-tech-stack": {
      title: "FlowState's Core Application Development",
      fullDescription: `
        Developed a comprehensive full-stack solution for FlowState's core business application, serving as the primary platform for client interactions.
        
        Project Highlights:
        - Built complete frontend and backend architecture
        - Implemented secure client data management system
        - Developed scalable cloud infrastructure on Azure
        - Created intuitive user interface with Tailwind CSS
        
        Technical Implementation:
        - Django backend with robust API architecture
        - Modern frontend using Tailwind CSS
        - SQL Server database integration
        - Azure cloud infrastructure setup
        
        Outcomes:
        - Successful deployment of client-facing application
        - Scalable and maintainable codebase
        - Efficient data management system
        - Cloud-ready infrastructure
      `,
      technologiesUsed: [
        "Django",
        "Tailwind CSS",
        "SQL Server",
        "Azure",
        "REST APIs"
      ],
      languages: [
        "Python",
        "JavaScript",
        "SQL",
        "HTML",
        "CSS"
      ],
      developmentTypes: [
        "Full Stack Development",
        "Cloud Architecture",
        "Database Design",
        "API Development"
      ],
      image: "flowstate.png",
      duration: "8 months",
      teamSize: "10 members"
    },
    "goal-tracking-app": {
      title: "iOS Goal Tracking Application",
      fullDescription: `
        Independently developed a sophisticated iOS application for automated goal tracking, integrating with financial services through Plaid API.
        
        Project Highlights:
        - Developed native iOS application using Swift
        - Created Django backend API infrastructure
        - Implemented Plaid integration for automated tracking
        - Designed intuitive user interface
        
        Technical Implementation:
        - Swift-based iOS application
        - Django REST API backend
        - Plaid API integration
        - Cloud hosting setup
        
        Current Status:
        - Successfully deployed to alpha testing
        - Gathering user feedback
        - Implementing iterative improvements
        - Preparing for beta release
      `,
      technologiesUsed: [
        "Swift",
        "Django",
        "Plaid API",
        "REST APIs",
        "Cloud Hosting"
      ],
      languages: [
        "Swift",
        "Python",
        "SQL"
      ],
      developmentTypes: [
        "iOS Development",
        "Backend Development",
        "API Integration",
        "Mobile UI/UX"
      ],
      image: "/goalcopilot.png",
      duration: "6 months",
      teamSize: "8 members"
    }
  };

  const caseStudy = caseStudyDetails[slug];

  if (!caseStudy) {
    return <div className="text-center text-white">Case Study Not Found</div>;
  }

  const handleGoBack = () => {
    navigate(-1);
  };

  const formatDescription = (text) => {
    return text.split('\n\n').map((section, index) => {
      const lines = section.trim().split('\n');
      if (lines[0].includes(':')) {
        const [title, ...items] = lines;
        return (
          <div key={index} className="mb-8">
            <h3 className="text-xl font-semibold mb-4">{title}</h3>
            <ul className="list-none space-y-2">
              {items.map((item, i) => (
                <li key={i} className="text-gray-300">
                  {item.replace('- ', '')}
                </li>
              ))}
            </ul>
          </div>
        );
      }
      return (
        <p key={index} className="text-gray-300 mb-8">
          {section}
        </p>
      );
    });
  };

  return (
    <div className="bg-gradient-to-b from-blue-950 to-black min-h-screen text-gray-100">
      <div className="max-w-3xl mx-auto px-4 py-8 md:py-16">
        <button 
          onClick={handleGoBack} 
          className="group flex items-center text-gray-400 hover:text-white transition-colors duration-200 mb-12"
        >
          <ArrowLeft className="mr-2 group-hover:-translate-x-1 transition-transform duration-200" size={20} />
          <span className="text-sm font-medium">Back to Case Studies</span>
        </button>

        <div className="space-y-6 mb-12">
          <h1 className="text-4xl md:text-5xl font-bold leading-tight bg-gradient-to-r from-white to-gray-300 bg-clip-text text-transparent">
            {caseStudy.title}
          </h1>

          <div className="flex flex-wrap gap-6 text-sm text-gray-300">
            <div className="flex items-center gap-2">
              <Calendar size={16} className="text-blue-400" />
              <span className="font-medium">Duration:</span>
              <span>{caseStudy.duration}</span>
            </div>
            <div className="flex items-center gap-2">
              <Users size={16} className="text-blue-400" />
              <span className="font-medium">Team Size:</span>
              <span>{caseStudy.teamSize}</span>
            </div>
          </div>
        </div>

        <div className="relative mb-12">
          <div className="absolute inset-0 bg-blue-600 opacity-10 rounded-xl"></div>
          <img 
            src={caseStudy.image} 
            alt={caseStudy.title} 
            className="w-full rounded-xl shadow-2xl object-cover max-h-[500px]"
          />
        </div>

        <article className="prose prose-invert prose-lg max-w-none mb-16">
          <div className="space-y-8">
            {formatDescription(caseStudy.fullDescription)}
          </div>
        </article>

        <div className="space-y-12">
          <div className="border-t border-blue-900 pt-8">
            <h2 className="text-2xl font-semibold mb-6 flex items-center gap-3">
              <Clock className="text-blue-400" size={24} />
              <span>Technologies Used</span>
            </h2>
            <div className="flex flex-wrap gap-3">
              {caseStudy.technologiesUsed.map((tech) => (
                <span 
                  key={tech} 
                  className="px-4 py-2 rounded-full text-sm font-medium bg-blue-900/50 text-blue-200 border border-blue-800 hover:bg-blue-800/50 transition-colors duration-200"
                >
                  {tech}
                </span>
              ))}
            </div>
          </div>

          <div className="border-t border-blue-900 pt-8">
            <h2 className="text-2xl font-semibold mb-6 flex items-center gap-3">
              <Code className="text-blue-400" size={24} />
              <span>Languages Used</span>
            </h2>
            <div className="flex flex-wrap gap-3">
              {caseStudy.languages.map((language) => (
                <span 
                  key={language} 
                  className="px-4 py-2 rounded-full text-sm font-medium bg-indigo-900/50 text-indigo-200 border border-indigo-800 hover:bg-indigo-800/50 transition-colors duration-200"
                >
                  {language}
                </span>
              ))}
            </div>
          </div>

          <div className="border-t border-blue-900 pt-8">
            <h2 className="text-2xl font-semibold mb-6 flex items-center gap-3">
              <Layout className="text-blue-400" size={24} />
              <span>Development Types</span>
            </h2>
            <div className="flex flex-wrap gap-3">
              {caseStudy.developmentTypes.map((type) => (
                <span 
                  key={type} 
                  className="px-4 py-2 rounded-full text-sm font-medium bg-purple-900/50 text-purple-200 border border-purple-800 hover:bg-purple-800/50 transition-colors duration-200"
                >
                  {type}
                </span>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CaseStudyDetail;