import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';

const FeatureDetail = () => {
  const { slug } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const featureDetails = {
    "analytics-solutions": {
      title: "Analytics Solutions",
      subtitle: "Transform raw data into actionable business intelligence",
      introduction: `In today's data-driven world, businesses are collecting more information than ever before. However, the true value lies not in the collection of data, but in the insights and actions it enables. Our analytics solutions bridge the gap between raw data and strategic decision-making, helping organizations of all sizes unlock the full potential of their information.`,
      howWeHelp: `We approach analytics as a transformative tool that can reshape how organizations operate and make decisions. Our process begins with understanding your specific business challenges and goals. From there, we design and implement customized analytics solutions that go beyond simple reporting to provide genuine business intelligence.
  
      We focus on creating intuitive dashboards and reports that make complex data accessible to decision-makers at all levels. Our solutions combine historical analysis with predictive modeling, enabling you to both understand past performance and anticipate future trends. We ensure that your analytics implementation not only provides insights but integrates seamlessly with your existing workflows and decision-making processes.`,
      valueProposition: `By implementing robust analytics solutions, organizations gain the ability to make data-driven decisions with confidence. This translates into numerous tangible benefits:
  
      • Reduced operational costs through identification of inefficiencies
      • Improved customer satisfaction through better understanding of behavior patterns
      • Enhanced revenue through identification of new opportunities
      • More effective resource allocation based on actual usage patterns
      • Faster response to market changes through real-time monitoring
      • Better risk management through predictive analysis`,
      caseExample: `Consider a mid-sized retail chain facing challenges with inventory management and customer retention. Through our analytics solution, we implemented a comprehensive system that transformed their operations:
  
      The Challenge:
      The company was struggling with stockouts of popular items while maintaining excess inventory of others. They also had limited insight into customer buying patterns and the effectiveness of their marketing campaigns.
  
      Our Solution:
      We developed an integrated analytics platform that combined point-of-sale data, inventory levels, and customer information. The system included:
      • Real-time inventory tracking across all locations
      • Customer purchase pattern analysis
      • Predictive modeling for demand forecasting
      • Marketing campaign effectiveness measurement
      • Custom dashboards for different management levels
  
      The Impact:
      Within six months, the company saw remarkable improvements:
      • 30% reduction in stockouts
      • 25% decrease in excess inventory
      • 20% increase in customer retention
      • 15% improvement in marketing ROI`,
      conclusion: `Analytics is not just about numbers and charts – it's about transforming data into action. Whether you're a small business looking to understand your customers better or a large enterprise seeking to optimize complex operations, the right analytics solution can provide the insights you need to thrive in today's competitive landscape.`,
      gradient: "from-violet-500 to-purple-500"
    },
    "web-application-development": {
      title: "Web Application Development",
      subtitle: "Build powerful, scalable web applications for the modern era",
      introduction: `In today's digital landscape, web applications are the backbone of business operations and customer engagement. Our web development solutions combine cutting-edge technology with user-centric design to create applications that drive growth and enhance user experience.`,
      howWeHelp: `Our approach to web application development is comprehensive and collaborative. We work closely with clients through every phase:
  
      • Requirements Analysis and Planning
      • Architecture Design and Technology Selection
      • UI/UX Design and Prototyping
      • Agile Development and Testing
      • Deployment and Maintenance
      
      We specialize in creating responsive, secure, and scalable applications that work seamlessly across all devices and platforms.`,
      valueProposition: `A well-designed web application delivers significant advantages:
  
      • Increased operational efficiency through process automation
      • Enhanced customer engagement and satisfaction
      • Improved market reach and accessibility
      • Reduced operational costs
      • Better data collection and analysis capabilities
      • Scalable infrastructure that grows with your business`,
      caseExample: `A leading educational institution needed to transform their traditional learning management system:
  
      The Challenge:
      They required a modern, interactive platform that could support thousands of concurrent users while providing personalized learning experiences.
  
      Our Solution:
      We developed a comprehensive learning management system featuring:
      • Real-time collaboration tools
      • Interactive content delivery
      • Progress tracking and analytics
      • Integration with existing systems
      • Mobile-first responsive design
  
      The Impact:
      The new platform achieved:
      • 200% increase in student engagement
      • 45% improvement in course completion rates
      • 60% reduction in administrative overhead
      • 90% positive user feedback`,
      conclusion: `Web applications are more than just tools – they're the digital foundation of modern business. Our development approach ensures you get a solution that's not only technically excellent but also aligned with your business objectives.`,
      gradient: "from-blue-500 to-cyan-500"
    },
    "data-collection": {
      title: "Data Collection",
      subtitle: "Gather and validate data with precision and efficiency",
      introduction: `Effective data collection is the foundation of any data-driven strategy. We provide comprehensive solutions that ensure accurate, timely, and secure data gathering across multiple channels and sources.`,
      howWeHelp: `We implement robust data collection systems that:
  
      • Automate data gathering processes
      • Validate data in real-time
      • Integrate multiple data sources
      • Ensure data quality and consistency
      • Maintain security and compliance
      • Scale with growing data volumes`,
      valueProposition: `Proper data collection infrastructure provides:
  
      • Higher quality data for better decision-making
      • Reduced manual data entry errors
      • Improved operational efficiency
      • Better regulatory compliance
      • Enhanced data security
      • Real-time data availability`,
      caseExample: `A healthcare provider needed to modernize their patient data collection:
  
      The Challenge:
      Manual data entry was causing errors and delays in patient care, while compliance requirements demanded better data handling.
  
      Our Solution:
      Implemented an integrated data collection system:
      • Electronic intake forms
      • Real-time validation
      • Automated data processing
      • Secure storage and transmission
      • Compliance monitoring
  
      The Impact:
      Results after implementation:
      • 90% reduction in data entry errors
      • 70% faster patient processing
      • 100% compliance achievement
      • 50% reduction in administrative costs`,
      conclusion: `Quality data collection is crucial for successful digital transformation. Our solutions ensure you capture the right data, in the right way, at the right time.`,
      gradient: "from-emerald-500 to-teal-500"
    },
    "database-management": {
      title: "Database Management",
      subtitle: "Optimize your data storage and access for peak performance",
      introduction: `Effective database management is crucial for maintaining data integrity, accessibility, and performance. We provide comprehensive database solutions that ensure your data is secure, organized, and readily available when needed.`,
      howWeHelp: `Our database management services include:
  
      • Database design and optimization
      • Performance tuning and monitoring
      • Backup and recovery solutions
      • Security implementation
      • Migration services
      • 24/7 maintenance and support`,
      valueProposition: `Professional database management delivers:
  
      • Improved data accessibility and reliability
      • Enhanced performance and speed
      • Better data security and compliance
      • Reduced maintenance costs
      • Scalable data storage solutions
      • Disaster recovery capability`,
      caseExample: `A financial services firm needed to overhaul their database systems:
  
      The Challenge:
      Growing data volumes were causing performance issues and security concerns.
  
      Our Solution:
      Implemented a comprehensive database management system:
      • Optimized database architecture
      • Advanced security measures
      • Automated backup systems
      • Performance monitoring tools
      • Scalable infrastructure
  
      The Impact:
      The solution achieved:
      • 80% faster query performance
      • Zero security breaches
      • 99.99% uptime
      • 40% reduction in storage costs`,
      conclusion: `Robust database management is essential for modern business operations. Our solutions ensure your data remains a valuable asset rather than a liability.`,
      gradient: "from-orange-500 to-amber-500"
    },
    "data-pipeline-automation": {
      title: "Data Pipeline Automation",
      subtitle: "Streamline your data workflows for maximum efficiency",
      introduction: `Automated data pipelines are essential for managing the flow of data across modern organizations. Our solutions create efficient, reliable data workflows that eliminate manual processes and ensure data consistency.`,
      howWeHelp: `We create automated pipelines that:
  
      • Extract data from multiple sources
      • Transform data for specific needs
      • Load data into target systems
      • Monitor data quality
      • Handle errors automatically
      • Scale with growing data volumes`,
      valueProposition: `Automated data pipelines provide:
  
      • Reduced manual processing time
      • Improved data accuracy
      • Real-time data availability
      • Better resource utilization
      • Increased operational efficiency
      • Enhanced data governance`,
      caseExample: `A global manufacturer needed to automate their supply chain data:
  
      The Challenge:
      Manual data processing was causing delays and errors in supply chain management.
  
      Our Solution:
      Built automated data pipelines featuring:
      • Real-time data integration
      • Automated quality checks
      • Custom transformations
      • Error handling
      • Performance monitoring
  
      The Impact:
      The automation achieved:
      • 95% reduction in processing time
      • 99% data accuracy
      • Real-time inventory updates
      • 60% cost savings in data operations`,
      conclusion: `Automated data pipelines are crucial for maintaining competitive advantage. Our solutions ensure your data flows efficiently and reliably throughout your organization.`,
      gradient: "from-pink-500 to-rose-500"
    },
    "full-stack-development": {
      title: "Full Stack Development",
      subtitle: "End-to-end solutions for complex digital challenges",
      introduction: `Full stack development provides comprehensive solutions that seamlessly integrate frontend and backend technologies. We deliver complete applications that provide exceptional user experiences while maintaining robust backend functionality.`,
      howWeHelp: `Our full stack development approach covers:
  
      • Frontend development
      • Backend systems
      • Database design
      • API development
      • Security implementation
      • Testing and optimization
      • Deployment and maintenance`,
      valueProposition: `Full stack development delivers:
  
      • Seamless user experiences
      • Consistent functionality
      • Faster development cycles
      • Better system integration
      • Improved maintenance
      • Scalable architecture`,
      caseExample: `A startup needed a complete e-commerce platform:
  
      The Challenge:
      They required a scalable, feature-rich platform built from scratch.
  
      Our Solution:
      Developed a full stack e-commerce solution:
      • Responsive frontend
      • Secure payment processing
      • Inventory management
      • Customer analytics
      • Admin dashboard
  
      The Impact:
      The platform achieved:
      • 50,000+ active users
      • 99.9% uptime
      • 3-second average page load
      • 40% increase in conversions`,
      conclusion: `Full stack development is vital for creating cohesive digital solutions. Our expertise ensures your applications are robust, scalable, and user-friendly from end to end.`,
      gradient: "from-indigo-500 to-blue-500"
    }
  };

  const feature = featureDetails[slug];

  if (!feature) {
    return <div className="text-center text-white">Feature Not Found</div>;
  }

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <div className="bg-gradient-to-b from-blue-950 to-black min-h-screen text-gray-100">
      <div className="max-w-4xl mx-auto px-4 py-8 md:py-16">
        <button 
          onClick={handleGoBack} 
          className="group flex items-center text-gray-400 hover:text-white transition-colors duration-200 mb-12"
        >
          <ArrowLeft className="mr-2 group-hover:-translate-x-1 transition-transform duration-200" size={20} />
          <span className="text-sm font-medium">Back to Services</span>
        </button>

        <article className="prose prose-invert prose-lg max-w-none">
          <h1 className="text-4xl md:text-5xl font-bold leading-tight bg-gradient-to-r from-white to-gray-300 bg-clip-text text-transparent mb-6">
            {feature.title}
          </h1>
          
          <p className="text-xl text-gray-300 mb-12">
            {feature.subtitle}
          </p>

          <div className="space-y-12">
            <div>
              {feature.introduction}
            </div>

            <div>
              <h2 className="text-2xl font-semibold mb-4">How We Help</h2>
              <div className="text-gray-300 space-y-4 whitespace-pre-line">
                {feature.howWeHelp}
              </div>
            </div>

            <div>
              <h2 className="text-2xl font-semibold mb-4">The Value for Your Business</h2>
              <div className="text-gray-300 space-y-4 whitespace-pre-line">
                {feature.valueProposition}
              </div>
            </div>

            <div>
              <h2 className="text-2xl font-semibold mb-4">Real-World Application</h2>
              <div className="text-gray-300 space-y-4 whitespace-pre-line">
                {feature.caseExample}
              </div>
            </div>

            <div className="border-t border-blue-900 pt-8">
              <h2 className="text-2xl font-semibold mb-4">Looking Forward</h2>
              <div className="text-gray-300">
                {feature.conclusion}
              </div>
            </div>
          </div>
        </article>
      </div>
    </div>
  );
};

export default FeatureDetail;