import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';

const PrivacyPolicy = () => {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <div className="bg-gradient-to-b from-blue-950 to-black min-h-screen text-gray-100">
      <div className="max-w-4xl mx-auto px-4 py-8 md:py-16">
        <button 
          onClick={handleGoBack} 
          className="group flex items-center text-gray-400 hover:text-white transition-colors duration-200 mb-12"
        >
          <ArrowLeft className="mr-2 group-hover:-translate-x-1 transition-transform duration-200" size={20} />
          <span className="text-sm font-medium">Back</span>
        </button>

        <h1 className="text-4xl md:text-5xl font-bold leading-tight bg-gradient-to-r from-white to-gray-300 bg-clip-text text-transparent mb-12">
          Privacy Policy
        </h1>

        <div className="space-y-6 text-gray-300">
          <p>Last updated: {new Date().toLocaleDateString()}</p>
          
          <h2 className="text-2xl font-semibold text-white mt-8">1. Information We Collect</h2>
          <p>
            We collect information that you provide directly to us, information we obtain automatically when you visit our website, 
            and information from third-party sources in accordance with applicable law.
          </p>

          <h2 className="text-2xl font-semibold text-white mt-8">2. How We Use Your Information</h2>
          <p>
            We use the information we collect to provide our services, communicate with you, improve our services,
            and comply with legal obligations.
          </p>

          <h2 className="text-2xl font-semibold text-white mt-8">3. Information Sharing</h2>
          <p>
            We do not sell your personal information. We may share your information with service providers,
            business partners, and other third parties as described in this policy.
          </p>

          <h2 className="text-2xl font-semibold text-white mt-8">4. Your Rights</h2>
          <p>
            You have certain rights regarding your personal information, including the right to access,
            correct, or delete your personal information.
          </p>

          <h2 className="text-2xl font-semibold text-white mt-8">5. Contact Us</h2>
          <p>
            If you have any questions about this Privacy Policy, please contact us at:<br />
            Email: support@dobbstechnologies.com<br />
            Phone: (925) 963-5432
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;