import React from "react";



const SecondCTA = () => {
    return (
        <section className="relative bg-blue-950 overflow-hidden">
            {/* Decorative background elements */}


            {/* Content */}
            <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24 lg:py-32">
                <div className="flex flex-col lg:flex-row items-center justify-between gap-12 lg:gap-24">
                    {/* Left side - Text content */}
                    <div className="flex-1 text-center lg:text-left">
                        <h2 className="text-3xl sm:text-4xl lg:text-5xl font-bold tracking-tight text-white">
                            Ready to Transform Your Business?
                        </h2>
                        <p className="mt-6 text-lg text-gray-300 max-w-2xl mx-auto lg:mx-0">
                            Join forward-thinking companies who have already elevated their technical capabilities. Start your transformation today with a free consultation.
                        </p>

                        {/* Benefits list */}
                        <div className="mt-8 grid grid-cols-1 sm:grid-cols-2 gap-4 text-left max-w-2xl mx-auto lg:mx-0">
                            {[
                                "No-obligation consultation",
                                "Custom solution design",
                                "Transparent pricing",
                                "Quick implementation"
                            ].map((benefit, index) => (
                                <div key={index} className="flex items-center gap-2">
                                    <svg
                                        className="h-5 w-5 text-blue-400"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth={2}
                                            d="M9 12l2 2 4-4"
                                        />
                                    </svg>
                                    <span className="text-gray-300">{benefit}</span>
                                </div>
                            ))}
                        </div>
                    </div>

                    {/* Right side - CTA Card */}
                    <div className="flex-1 w-full max-w-md">
                        <div className="bg-white/10 backdrop-blur-xl rounded-2xl p-8 border border-white/10">
                            <div className="space-y-6">
                                <div className="bg-gradient-to-br from-blue-500 to-cyan-500 text-white p-4 rounded-xl inline-block">
                                    <svg
                                        className="h-6 w-6"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth={2}
                                            d="M13 10V3L4 14h7v7l9-11h-7z"
                                        />
                                    </svg>
                                </div>
                                <h3 className="text-2xl font-semibold text-white">Get Started Today</h3>
                                <p className="text-gray-300">
                                    Book your free consultation and receive a customized action plan within 48 hours.
                                </p>
                                <div className="space-y-3">
                                    <a
                                        href="https://calendly.com/databydobbs-support/30min"
                                        className="flex items-center justify-center w-full rounded-full bg-gradient-to-r from-blue-500 to-cyan-500 px-6 py-4 text-sm font-semibold text-white shadow-sm hover:from-blue-600 hover:to-cyan-600 transition-all duration-300"
                                    >
                                        Schedule Free Consultation
                                    </a>
                                    
                                </div>
                                <p className="text-sm text-gray-400 text-center">
                                    No commitment required • 100% free consultation
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default SecondCTA;