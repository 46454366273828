import React from 'react';
import { ArrowUpRight} from 'lucide-react';
import { useNavigate } from 'react-router-dom';

const CaseStudiesGrid = () => {
  const navigate = useNavigate();
  
  const caseStudies = [
    {
      id: 1,
      title: "Website Development for Chiropractic Practice",
      description: "Developed and optimized a Django-based marketing website for a chiropractic company, enhancing their online presence and SEO to attract more clients.",
      category: "Website Design",
      duration: "3 months",
      teamSize: "6 members",
      image: "/amjchiro.png",
      gradient: "from-violet-500 to-purple-500",
      slug: "chiropractor-website"
    },
    {
      id: 2,
      title: "Developed iOS app for goal tracking",
      description: "Developed an iOS app for personal development, automating goal tracking with Swift and a Django backend to enhance user productivity.",
      category: "App Development",
      duration: "6 months",
      teamSize: "8 members",
      image: "/goalcopilot.png",
      gradient: "from-blue-500 to-cyan-500",
      slug: "goal-tracking-app"
    },
    {
      id: 3,
      title: "Infrastructure Bill Performance Support",
      description: "Created a Power BI dashboard for an executive meeting, analyzing and visualizing data including inflation's impact on the bill.",
      category: "Web Development",
      duration: "4 months",
      teamSize: "5 members",
      image: "/analytcs_dash.jpeg",
      gradient: "from-emerald-500 to-teal-500",
      slug: "infrastructure-bill-dashboard"
    },
    {
      id: 4,
      title: "Developed FlowState's Tech Stack",
      description: "Developed the complete tech stack for Flow State, creating their core web app and backend database, and advising on future technical strategies.",
      category: "AI/ML",
      duration: "8 months",
      teamSize: "10 members",
      image: "flowstate.png",
      gradient: "from-indigo-500 to-blue-500",
      slug: "flowstate-tech-stack"
    }
  ];

  const handleCaseStudyClick = (slug) => {
    navigate(`/case-studies/${slug}`);
  };

  return (
    <section id="case-studies" className="bg-blue-950 py-24">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-4xl font-bold tracking-tight text-white sm:text-5xl">
            Our Featured Work
          </h2>
          <p className="mt-4 text-lg text-gray-300 max-w-3xl mx-auto">
            Explore our portfolio of successful projects and discover how we've helped businesses transform their digital presence.
          </p>
        </div>

        <div className="mt-20 grid grid-cols-1 gap-8 sm:grid-cols-2">
          {caseStudies.map((study) => (
            <div
              key={study.id}
              onClick={() => handleCaseStudyClick(study.slug)}
              className="relative group bg-blue-900/40 rounded-3xl transition-all duration-300 hover:scale-[1.02] cursor-pointer"
            >
              <div className="relative z-10">
                <div className="relative aspect-[16/9] overflow-hidden rounded-t-3xl">
                  <img
                    src={study.image}
                    alt={study.title}
                    className="w-full h-full object-cover transition-transform duration-500 group-hover:scale-105"
                  />
                  <div className="absolute inset-0 bg-gradient-to-b from-transparent to-blue-950/80" />
                </div>

                <div className="p-8">
                  <div className={`inline-flex rounded-full text-sm font-medium bg-gradient-to-r ${study.gradient} text-white px-4 py-1 mb-4`}>
                    {study.category}
                  </div>
                  
                  <h3 className="text-xl font-semibold text-white mb-2">
                    {study.title}
                  </h3>
                  
                  <p className="text-gray-300 text-sm leading-relaxed mb-6">
                    {study.description}
                  </p>
                  
                  

                  <div className="inline-flex items-center text-sm font-medium text-white">
                    View Case Study
                    <ArrowUpRight className="ml-2 h-4 w-4 transition-transform duration-300 group-hover:translate-x-1" />
                  </div>
                </div>
              </div>

              <div 
                className={`absolute inset-0 opacity-0 bg-gradient-to-r ${study.gradient} transition-opacity duration-300 group-hover:opacity-10 rounded-3xl`}
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default CaseStudiesGrid;