import React from "react";
import { useNavigate } from 'react-router-dom';
import { ArrowRight } from 'lucide-react';

const Features = () => {
    const navigate = useNavigate();
    
    const features = [
        {
            title: "Analytics Solutions",
            description: "Turn complex data into clear, actionable insights with advanced analytics and visualization tools",
            icon: (
                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" />
                </svg>
            ),
            gradient: "from-violet-500 to-purple-500",
            slug: "analytics-solutions"
        },
        {
            title: "Web Application Development",
            description: "Building modern, responsive web applications with cutting-edge technologies and frameworks",
            icon: (
                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4" />
                </svg>
            ),
            gradient: "from-blue-500 to-cyan-500",
            slug: "web-application-development"
        },
        {
            title: "Data Collection",
            description: "Comprehensive data gathering solutions with efficient collection and validation processes",
            icon: (
                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 5a1 1 0 011-1h14a1 1 0 011 1v2a1 1 0 01-1 1H5a1 1 0 01-1-1V5zM4 13a1 1 0 011-1h6a1 1 0 011 1v6a1 1 0 01-1 1H5a1 1 0 01-1-1v-6zM16 13a1 1 0 011-1h2a1 1 0 011 1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-6z" />
                </svg>
            ),
            gradient: "from-emerald-500 to-teal-500",
            slug: "data-collection"
        },
        {
            title: "Database Management",
            description: "Expert database architecture and optimization for efficient data storage and retrieval",
            icon: (
                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 7v10c0 2 1.5 3 3.5 3h9c2 0 3.5-1 3.5-3V7c0-2-1.5-3-3.5-3h-9C5.5 4 4 5 4 7zm8 13v-6m-4 2l4 4 4-4" />
                </svg>
            ),
            gradient: "from-orange-500 to-amber-500",
            slug: "database-management"
        },
        {
            title: "Data Pipeline Automation",
            description: "Streamline your data workflows with automated pipelines and ETL processes",
            icon: (
                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                </svg>
            ),
            gradient: "from-pink-500 to-rose-500",
            slug: "data-pipeline-automation"
        },
        {
            title: "Full Stack Development",
            description: "End-to-end development services covering both frontend and backend technologies",
            icon: (
                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 9l3 3-3 3m5 0h3M5 20h14a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                </svg>
            ),
            gradient: "from-indigo-500 to-blue-500",
            slug: "full-stack-development"
        }
    ];

    const handleFeatureClick = (slug) => {
        navigate(`/services/${slug}`);
    };

    return (
        <section id="services" className="bg-blue-950 py-24">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="text-center">
                    <h2 className="text-4xl font-bold tracking-tight text-white sm:text-5xl">
                        Our Services
                    </h2>
                    <p className="mt-4 text-lg text-gray-300 max-w-3xl mx-auto">
                        Transform your business with our comprehensive data and development solutions.
                    </p>
                </div>

                <div className="mt-20 grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
                    {features.map((feature, index) => (
                        <div
                            key={index}
                            onClick={() => handleFeatureClick(feature.slug)}
                            className="relative group bg-blue-900/40 p-8 rounded-3xl transition-all duration-300 hover:scale-[1.02] cursor-pointer"
                        >
                            <div className="relative z-10">
                                <div className={`inline-flex rounded-2xl bg-gradient-to-r ${feature.gradient} p-3`}>
                                    {feature.icon}
                                </div>
                                
                                <h3 className="mt-6 text-xl font-semibold text-white">
                                    {feature.title}
                                </h3>
                                
                                <p className="mt-2 text-gray-300 text-sm leading-relaxed">
                                    {feature.description}
                                </p>
                                
                                <div className="mt-6 inline-flex items-center text-sm font-medium text-white">
                                    Learn more
                                    <ArrowRight className="ml-2 h-4 w-4 transition-transform duration-300 group-hover:translate-x-1" />
                                </div>
                            </div>

                            <div 
                                className={`absolute inset-0 opacity-0 bg-gradient-to-r ${feature.gradient} transition-opacity duration-300 group-hover:opacity-10 rounded-3xl`}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Features;