import React from "react";
import { useNavigate, useLocation } from "react-router-dom";

const Header = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const handleSectionNavigation = (sectionId) => {
        if (location.pathname !== '/') {
            // If not on home page, navigate home first
            navigate('/', { state: { scrollTo: sectionId } });
        } else {
            // If on home page, scroll directly
            const section = document.getElementById(sectionId);
            if (section) {
                section.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        }
    };

    const handleContactClick = (e) => {
        e.preventDefault();
        handleSectionNavigation('contact');
    };

    const isDetailPage = location.pathname.includes('/services/') || location.pathname.includes('/case-studies/');

    return (
        <div className="relative bg-blue-950">
            <div className="absolute inset-0">
                <div className="absolute -top-48 left-0 w-96 h-96 bg-gradient-to-br from-blue-500/20 to-cyan-500/20 rounded-full blur-3xl" />
                <div className="absolute top-0 right-0 w-96 h-96 bg-gradient-to-br from-indigo-500/20 to-purple-500/20 rounded-full blur-3xl" />
            </div>

            <nav
                className="relative mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8"
                aria-label="Global"
            >
                <div className="flex lg:flex-1">
                    <a href="/" className="-m-1.5 p-1.5 flex items-center group">
                        <span className="font-bold text-xl tracking-tight">
                            <span className="bg-gradient-to-r from-blue-400 to-cyan-400 bg-clip-text text-transparent">Dobbs</span>
                            <span className="text-white">Technologies</span>
                        </span>
                        <div className="h-[2px] w-0 group-hover:w-full bg-gradient-to-r from-blue-400 to-cyan-400 transition-all duration-300" />
                    </a>
                </div>
                <div className="flex lg:hidden">
                    <button
                        type="button"
                        className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-400"
                    >
                        <span className="sr-only">Open main menu</span>
                        <svg
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            aria-hidden="true"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                            />
                        </svg>
                    </button>
                </div>
                <div className="hidden lg:flex lg:gap-x-12">
                    <button 
                        onClick={() => handleSectionNavigation('services')} 
                        className="text-sm font-semibold text-gray-300 hover:text-white transition-colors duration-300"
                    >
                        Our Services
                    </button>
                    <button 
                        onClick={() => handleSectionNavigation('case-studies')} 
                        className="text-sm font-semibold text-gray-300 hover:text-white transition-colors duration-300"
                    >
                        Our Projects
                    </button>
                    <button 
                        onClick={() => handleSectionNavigation('about')} 
                        className="text-sm font-semibold text-gray-300 hover:text-white transition-colors duration-300"
                    >
                        About Us
                    </button>
                    <button 
                        onClick={() => handleSectionNavigation('testimonials')} 
                        className="text-sm font-semibold text-gray-300 hover:text-white transition-colors duration-300"
                    >
                        Testimonials
                    </button>
                </div>
                <div className="hidden lg:flex lg:flex-1 lg:justify-end">
                    <button 
                        onClick={handleContactClick}
                        className="text-sm font-semibold text-gray-300 hover:text-white transition-colors duration-300"
                    >
                        Contact Us<span aria-hidden="true">&rarr;</span>
                    </button>
                </div>
            </nav>

            {/* Mobile menu - update the links here too */}
            <div className="lg:hidden" role="dialog" aria-modal="true">
                <div className="fixed inset-0 z-10"></div>
                <div className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-blue-900/90 backdrop-blur-xl px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-blue-800/10">
                    <div className="flex items-center justify-between">
                        <a href="/" className="-m-1.5 p-1.5 flex items-center group">
                            <span className="font-bold text-xl tracking-tight">
                                <span className="bg-gradient-to-r from-blue-400 to-cyan-400 bg-clip-text text-transparent">Dobbs</span>
                                <span className="text-white">Technologies</span>
                            </span>
                            <div className="h-[2px] w-0 group-hover:w-full bg-gradient-to-r from-blue-400 to-cyan-400 transition-all duration-300" />
                        </a>
                        <button
                            type="button"
                            className="-m-2.5 rounded-md p-2.5 text-gray-400"
                        >
                            <span className="sr-only">Close menu</span>
                            <svg
                                className="h-6 w-6"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="currentColor"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M6 18L18 6M6 6l12 12"
                                />
                            </svg>
                        </button>
                    </div>
                    <div className="mt-6 flow-root">
                        <div className="-my-6 divide-y divide-blue-800/25">
                            <div className="space-y-2 py-6">
                                <button
                                    onClick={() => handleSectionNavigation('services')}
                                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold text-white hover:bg-blue-800/50 w-full text-left"
                                >
                                    Our Services
                                </button>
                                <button
                                    onClick={() => handleSectionNavigation('case-studies')}
                                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold text-white hover:bg-blue-800/50 w-full text-left"
                                >
                                    Our Projects
                                </button>
                                <button
                                    onClick={() => handleSectionNavigation('about')}
                                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold text-white hover:bg-blue-800/50 w-full text-left"
                                >
                                    About Us
                                </button>
                                <button
                                    onClick={() => handleSectionNavigation('testimonials')}
                                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold text-white hover:bg-blue-800/50 w-full text-left"
                                >
                                    Testimonials
                                </button>
                            </div>
                            <div className="py-6">
                                <button
                                    onClick={handleContactClick}
                                    className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold text-white hover:bg-blue-800/50 w-full text-left"
                                >
                                    Contact Us
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {!isDetailPage && (
                <div className="relative pb-20 pt-24 sm:pb-24 sm:pt-32">
                    <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                        <div className="mx-auto max-w-4xl text-center">
                            <h1 className="font-display text-4xl font-bold tracking-tight text-white sm:text-6xl lg:text-7xl">
                                Technology Solutions{" "}
                                <span className="relative whitespace-nowrap">
                                    <svg
                                        aria-hidden="true"
                                        viewBox="0 0 418 42"
                                        className="absolute left-0 top-2/3 h-[0.58em] w-full fill-blue-500/40"
                                        preserveAspectRatio="none"
                                    >
                                        <path d="M203.371.916c-26.013-2.078-76.686 1.963-124.73 9.946L67.3 12.749C35.421 18.062 18.2 21.766 6.004 25.934 1.244 27.561.828 27.778.874 28.61c.07 1.214.828 1.121 9.595-1.176 9.072-2.377 17.15-3.92 39.246-7.496C123.565 7.986 157.869 4.492 195.942 5.046c7.461.108 19.25 1.696 19.17 2.582-.107 1.183-7.874 4.31-25.75 10.366-21.992 7.45-35.43 12.534-36.701 13.884-2.173 2.308-.202 4.407 4.442 4.734 2.654.187 3.263.157 15.593-.78 35.401-2.686 57.944-3.488 88.365-3.143 46.327.526 75.721 2.23 130.788 7.584 19.787 1.924 20.814 1.98 24.557 1.332l.066-.011c1.201-.203 1.53-1.825.399-2.335-2.911-1.31-4.893-1.604-22.048-3.261-57.509-5.556-87.871-7.36-132.059-7.842-23.239-.254-33.617-.116-50.627.674-11.629.54-42.371 2.494-46.696 2.967-2.359.259 8.133-3.625 26.504-9.81 23.239-7.825 27.934-10.149 28.304-14.005.417-4.348-3.529-6-16.878-7.066Z" />
                                    </svg>
                                    <span className="relative text-blue-400">that deliver</span>
                                </span>{" "}
                                real results.
                            </h1>
                            <p className="mx-auto mt-8 max-w-2xl text-lg tracking-tight text-gray-300">
                                We transform businesses through innovative technical solutions, data analytics, 
                                and expert consulting. Your success is our mission.
                            </p>
                            <div className="mt-10 flex justify-center gap-x-6">
                                <a
                                    href="https://calendly.com/databydobbs-support/30min"
                                    className="rounded-full bg-white px-8 py-4 text-sm font-semibold text-blue-950 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white transition-all duration-300"
                                >
                                    Schedule Consultation
                                </a>
                                <button
                                    onClick={handleContactClick}
                                    className="rounded-full px-8 py-4 text-sm font-semibold text-white border border-white/30 hover:bg-white/10 transition-all duration-300"
                                >
                                    <span className="flex items-center">
                                        <svg
                                            className="h-4 w-4 flex-none"
                                            fill="none"
                                            stroke="currentColor"
                                            strokeWidth="2"
                                            viewBox="0 0 24 24"
                                        >
                                            <path d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                                        </svg>
                                        <span className="ml-3">Contact Us</span>
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Header;