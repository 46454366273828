import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';

const CookiePolicy = () => {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <div className="bg-gradient-to-b from-blue-950 to-black min-h-screen text-gray-100">
      <div className="max-w-4xl mx-auto px-4 py-8 md:py-16">
        <button 
          onClick={handleGoBack} 
          className="group flex items-center text-gray-400 hover:text-white transition-colors duration-200 mb-12"
        >
          <ArrowLeft className="mr-2 group-hover:-translate-x-1 transition-transform duration-200" size={20} />
          <span className="text-sm font-medium">Back</span>
        </button>

        <h1 className="text-4xl md:text-5xl font-bold leading-tight bg-gradient-to-r from-white to-gray-300 bg-clip-text text-transparent mb-12">
          Cookie Policy
        </h1>

        <div className="space-y-6 text-gray-300">
          <p>Last updated: {new Date().toLocaleDateString()}</p>

          <h2 className="text-2xl font-semibold text-white mt-8">1. What Are Cookies</h2>
          <p>
            Cookies are small text files that are placed on your computer or mobile device when you visit
            our website. They are widely used to make websites work more efficiently and provide useful
            information to website owners.
          </p>

          <h2 className="text-2xl font-semibold text-white mt-8">2. How We Use Cookies</h2>
          <p>
            We use cookies to understand how you use our website, remember your preferences, and improve
            your experience. This includes:
          </p>
          <ul className="list-disc pl-6 mt-4">
            <li>Authentication and security</li>
            <li>Preferences and settings</li>
            <li>Analytics and performance</li>
          </ul>

          <h2 className="text-2xl font-semibold text-white mt-8">3. Managing Cookies</h2>
          <p>
            Most web browsers allow you to control cookies through their settings. You can usually find
            these settings in the "options" or "preferences" menu of your browser.
          </p>

          <h2 className="text-2xl font-semibold text-white mt-8">4. Contact Us</h2>
          <p>
            If you have any questions about our use of cookies, please contact us at:<br />
            Email: support@dobbstechnologies.com<br />
            Phone: (925) 963-5432
          </p>
        </div>
      </div>
    </div>
  );
};

export default CookiePolicy;